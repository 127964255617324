
<template>
    <div>
      <CCard accentColor="primary">
        <CCardBody>
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <CForm @submit.stop.prevent="handleSubmit(submit)">
              <CRow>
                <CCol md="12">
                  <validation-provider rules="required" v-slot="{ errors }" name="Instansi">
                    <div role="group" class="form-group">
                      <label class>Instansi</label>
                      <v-select
                        v-model="defaultSelected"
                        item-text="nama_instansi"
                        item-value="id_instansi"
                        disabled
                        :options="optionsInstansi"
                        label="nama_instansi"
                        :filterable="false"
                        @search="onSearch"
                        :class="[
                              { 'is-valid': !errors[0] },
                              { 'is-invalid': errors[0] },
                            ]"
                      ></v-select>
                      <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                    </div>
                  </validation-provider>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                  <validation-provider rules="required" v-slot="{ errors }" name="Unit Kerja">
                    <div role="group" class="form-group">
                      <label class>OPD</label>
                      <v-select
                        v-model="form.id_opd"
                        :options="optionsOPD"
                        label="nama_opd"
                        :reduce="opd => opd.id_opd"
                        :filterable="true"
                        @search="onSearchOPD"
                        :class="[
                              { 'is-valid': !errors[0] },
                              { 'is-invalid': errors[0] },
                            ]"
                      ></v-select>
                      <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                    </div>
                  </validation-provider>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                  <validation-provider rules="required" v-slot="{ errors }" name="Unit Kerja">
                    <div role="group" class="form-group">
                      <label class>Unit Kerja</label>
                      <v-select
                        v-model="selectedUnitKerja"
                        :options="optionsUnitKerja"
                        label="nama_unit_kerja"
                        :reduce="unit_kerja => unit_kerja"
                        :filterable="true"
                        @search="onSearchUnitKerja"
                        @input="onChangesUnitKerja($event)"
                        :class="[
                              { 'is-valid': !errors[0] },
                              { 'is-invalid': errors[0] },
                            ]"
                      ></v-select>
                      <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                    </div>
                  </validation-provider>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="4">
                    <validation-provider rules="required" v-slot="{ errors }" name="Jabatan">
                    <CInput
                        label="Jabatan"
                        placeholder="Jabatan"
                        v-model="form.jabatan"
                        :invalid-feedback="errors[0]"
                        :isValid="!errors[0]"
                        disabled
                    ></CInput>
                    </validation-provider>
                </CCol>
                <CCol md="4">
                  <validation-provider rules="required" v-slot="{ errors }" name="Eselon">
                    <div role="group" class="form-group">
                      <label class>Eselon</label>
                      <v-select
                        v-model="form.id_eselon"
                        :options="optionsEselon"
                        label="nama_eselon"
                        :reduce="eselon => eselon.id_eselon"
                        :filterable="true"
                        @search="onSearchEselon"
                        :class="[
                              { 'is-valid': !errors[0] },
                              { 'is-invalid': errors[0] },
                            ]"
                      ></v-select>
                      <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                    </div>
                  </validation-provider>
                </CCol>
                <CCol md="4">
                  <validation-provider rules="required" v-slot="{ errors }" name="Tingkat Pendidikan">
                    <div role="group" class="form-group">
                      <label class>Tingkat Pendidikan</label>
                      <v-select
                        v-model="form.id_tingkat_pendidikan"
                        :options="optionsTingkatPendidikan"
                        label="nama_tingkat_pendidikan"
                        :reduce="eselon => eselon.id_tingkat_pendidikan"
                        :filterable="true"
                        @search="onSearchTingkatPendidikan"
                        :class="[
                              { 'is-valid': !errors[0] },
                              { 'is-invalid': errors[0] },
                            ]"
                      ></v-select>
                      <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                    </div>
                  </validation-provider>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="6" class="text-left">
                  <CSpinner color="success" size="sm" v-if="loading" />
                  <CButton color="primary" class="px-4" type="submit" v-else>Simpan</CButton>
                </CCol>
                <CCol col="6" class="text-right">
                </CCol>
              </CRow>
            </CForm>
          </ValidationObserver>
        </CCardBody>
      </CCard>
    </div>
  </template>
  <script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import VSelect from "vue-select";
  import _ from "lodash";
  import Swal from "sweetalert2";
  export default {
    name: "FormKualifikasiJabatanStruktural",
    props: ["item"],
    components: {
      ValidationProvider,
      ValidationObserver,
      VSelect,
    },
    data() {
      return {
        id: null,
        errorMessage: null,
        defaultSelected: {
          nama_instansi: "Purwakarta",
          id_instansi: 3214,
        },
        form: {
          nama_instansi: "Purwakarta",
          id_instansi: 3214,
          id_opd: null,
          id_unit_kerja: null,
          jabatan: null,
          id_eselon: null,
          id_tingkat_pendidikan: null,
          note: null,
        },
        loading: false,
        optionsInstansi: [],
        optionsOPD: [],
        optionsUnitKerja: [],
        selectedUnitKerja: [],
        optionsEselon: [],
        optionsTingkatPendidikan: []
      };
    },
    watch: {
      "form.id_opd": function () {
        this.searchUnitKerja("", this);
      },
      item: function (item) {
        if (item) {
          const array2 = [];
          array2.push({
            id_unit_kerja: item.id_unit_kerja,
            nama_unit_kerja: item.nama_unit_kerja
          });
          this.optionsUnitKerja = array2;
          this.selectedUnitKerja = array2;
          const array4 = [];
          array4.push({
            id_opd: item.id_opd,
            nama_opd: item.nama_opd
          });
          this.optionsOPD = array4;

          const array5 = [];
          array5.push({
            id_eselon: item.id_eselon,
            nama_eselon: item.nama_eselon
          });
          this.optionsEselon = array5;

          const array6 = [];
          array6.push({
            id_tingkat_pendidikan: item.id_tingkat_pendidikan,
            nama_tingkat_pendidikan: item.nama_tingkat_pendidikan
          });
          this.optionsTingkatPendidikan = array6;

          this.form.id_opd = item.id_opd;
          this.form.id_unit_kerja = item.id_unit_kerja;
          this.form.jabatan = item.jabatan;
          this.form.id_eselon = item.id_eselon;
          this.form.id_tingkat_pendidikan = item.id_tingkat_pendidikan;
          this.form.note = item.note;
          this.id = item.id;
        }
      },
    },
    methods: {
      loadSelect() {
        this.search("", this);
      },
      async submit() {
        const vm = this;
        try {
          this.loading = true;
          let { status } = this.item
            ? await this.$store.dispatch("kualifikasi_jabatan_struktural/update", {
                data: this.form,
                id: vm.id,
              })
            : await this.$store.dispatch("kualifikasi_jabatan_struktural/store", this.form);
          if (status >= 200 && status <= 202) {
            Swal.fire({
              title: "Sukses",
              text: "Data berhasil tersimpan!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Tutup!",
            }).then(() => {
              if (!vm.item) {
                vm.form.id_instansi = null;
                vm.form.id_opd = null;
                vm.form.id_unit_kerja = null;
                vm.form.jabatan = null;
                vm.form.id_eselon = null;
                vm.form.id_tingkat_pendidikan = null;
                vm.form.note = null;
                vm.id = null;
                vm.selectedUnitKerja = [];
                requestAnimationFrame(() => {
                  this.$refs.observer.reset();
                });
              }
              vm.$emit("done");
            });
          }
        } catch (x) {
          console.log(x);
        } finally {
          this.loading = false;
        }
      },
      async onSearch(search, loading) {
        loading(true);
        await this.search(search, this);
        loading(false);
      },
      search: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch(
          "master_instansi/autocomplete",
          search
        );
        if (search) vm.optionsInstansi = options;
      }, 300),
      async onSearchOPD(search, loading) {
        loading(true);
        await this.searchOPD(search, this);
        loading(false);
      },
      searchOPD: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch(
          "kualifikasi_jabatan_struktural/autocomplete_opd",
          search
        );
        if (search) vm.optionsOPD = options;
      }, 300),
      async onSearchUnitKerja(search, loading) {
        loading(true);
        await this.searchUnitKerja(search, this);
        loading(false);
      },
      searchUnitKerja: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch("kualifikasi_jabatan_struktural/autocomplete_opd_unit_kerja", {
          search: search,
          id_opd: vm.form.id_opd
        });
        if (search) vm.optionsUnitKerja = options;
      }, 300),
      onChangesUnitKerja(val){
        this.form.id_unit_kerja = val.id_unit_kerja;
        this.form.jabatan = val.jabatan;
      },
      async onSearchEselon(search, loading) {
        loading(true);
        await this.searchEselon(search, this);
        loading(false);
      },
      searchEselon: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch(
          "kualifikasi_jabatan_struktural/autocomplete_eselon",
          search
        );
        if (search) vm.optionsEselon = options;
      }, 300),
      async onSearchTingkatPendidikan(search, loading) {
        loading(true);
        await this.searchTingkatPendidikan(search, this);
        loading(false);
      },
      searchTingkatPendidikan: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch(
          "kualifikasi_jabatan_struktural/autocomplete_tingkat_pendidikan",
          search
        );
        if (search) vm.optionsTingkatPendidikan = options;
      }, 300),
    },
  };
  </script>
  