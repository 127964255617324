<template>
    <div>
      <CCard accentColor="primary">
        <CCardHeader>
          <slot name="header">
            <CIcon name="cil-grid" />
            {{title}}
          </slot>
        </CCardHeader>
        <CCardBody>
          <CRow v-show="!formVisibility">
            <CCol md="6">
              <div role="group" class="form-group">
                <label class>OPD</label>
                <v-select
                  v-model="form.id_opd"
                  :options="optionsOPD"
                  label="nama_opd"
                  :reduce="opd => opd.id_opd"
                  :filterable="true"
                  @search="onSearchOPD"
                ></v-select>
              </div>
            </CCol>
            <CCol md="6">
              <div role="group" class="form-group">
                <label class>Unit Kerja</label>
                <v-select
                  v-model="form.id_unit_kerja"
                  :options="optionsUnitKerja"
                  label="nama_unit_kerja"
                  :reduce="unit_kerja => unit_kerja.id_unit_kerja"
                  :filterable="true"
                  @search="onSearchUnitKerja"
                ></v-select>
              </div>
            </CCol>
          </CRow>
          <CButtonGroup size="sm">
            <CButton color="info" v-show="!formVisibility" @click="loadData()">
              <CIcon name="cil-search" />Cari
            </CButton>
            <CButton color="success" @click="formVisibility = !formVisibility">
              <CIcon :name="icon_button" />
              {{button_label}}
            </CButton>
            <CButton color="warning" v-show="!formVisibility" @click="reset()">
              <CIcon name="cil-loop-circular" />Reset
            </CButton>
          </CButtonGroup>
          <hr />
          <CCollapse :show="formVisibility">
            <form-input @done="resetTabel" ref="formCreate" />
          </CCollapse>
          <CDataTable
            :items="computedItems"
            :fields="fields"
            hover
            small
            border
            v-show="!formVisibility"
            :loading="loading"
          >
            <template #show_details="{item, index}">
              <td class="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  class="m-1"
                  @click="toggleDetails(item, index)"
                >{{Boolean(item._toggled) ? 'Tutup' : 'Edit'}}</CButton>
                <CButton
                  size="sm"
                  color="danger"
                  variant="outline"
                  square
                  class="m-1"
                  @click="delete_item(item.id)"
                >Delete</CButton>
              </td>
            </template>
            <template #details="{item}">
              <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                <form-input @done="resetTabel" :item="item" :key="item.id" />
              </CCollapse>
            </template>
          </CDataTable>
          <CPagination
            :activePage.sync="page"
            :pages.sync="totalPage"
            size="sm"
            align="end"
            v-show="!formVisibility"
          />
        </CCardBody>
      </CCard>
    </div>
  </template>
  
  <script>
  import formInput from "./form";
  import Swal from "sweetalert2";
  import VSelect from "vue-select";
  import _ from "lodash";
  const fields = [
    { key: "nama_opd", label: "OPD" },
    { key: "nama_unit_kerja", label: "Unit Kerja" },
    { key: "jabatan", label: "Jabatan" },
    {
      key: "show_details",
      label: "Action",
      sorter: false,
      filter: false,
    },
  ];
  export default {
    name: "KualifikasiJabatanStruktural",
    components: {
      formInput,
      VSelect,
    },
    data() {
      return {
        items: [],
        fields,
        details: [],
        collapseDuration: 0,
        formVisibility: false,
        loading: false,
        page: 1,
        totalPage: 0,
        optionsOPD: [],
        optionsUnitKerja: [],
        optionsJabatanUmum: [],
        form: {
          id_opd: null,
          id_unit_kerja: null
        },
      };
    },
    mounted() {
      this.loadData();
      this.search("", this);
    },
    watch: {
      formVisibility: function (newValue) {
        if (newValue) this.$refs.formCreate.loadSelect();
      },
      page: function () {
        this.loadData();
      },
      "form.id_instansi": function () {
        this.searchUnitKerja("", this);
      },
    },
    computed: {
      computedItems() {
        return this.items.map((item) => {
          return {
            ...item
          };
        });
      },
      title() {
        return this.formVisibility
          ? "Form Kualifikasi Jabatan Struktural"
          : "Tabel Kualifikasi Jabatan Struktural";
      },
      button_label() {
        return !this.formVisibility ? "Tambah" : "Kembali";
      },
      icon_button() {
        return !this.formVisibility ? "cil-plus" : "cil-arrow-left";
      },
    },
    methods: {
      reset() {
        this.form.id_opd = null;
        this.form.id_unit_kerja = null;
        this.page = 1;
        this.loadData();
      },
      async loadData() {
        try {
          this.loading = true;
          let data = await this.$store.dispatch("kualifikasi_jabatan_struktural/index", {
            page: this.page,
            data: this.form,
          });
          this.items = data.data;
          this.totalPage = data.last_page;
        } catch (ex) {
          this.items = [];
        } finally {
          this.loading = false;
        }
      },
      resetTabel() {
        this.formVisibility = false;
        this.loadData();
      },
      toggleDetails(item, index) {
        this.items.forEach((element) => {
          this.$set(element, "_toggled", false);
        });
        this.$set(this.items[index], "_toggled", !item._toggled);
        this.collapseDuration = 300;
        this.$nextTick(() => {
          this.collapseDuration = 0;
        });
      },
      delete_item(id) {
        Swal.fire({
          title: "Peringatan!",
          text: "Apa Anda yakin ingin menghapus data ini?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Hapus!",
        }).then(async (value) => {
          console.log(value);
          if (value.isConfirmed) {
            await this.$store.dispatch("kualifikasi_jabatan_struktural/destroy", id);
            this.loadData();
            Swal.fire({
              title: "Ok!",
              text: "Data berhasil terhapus!",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok!",
            });
          }
        });
      },
      async onSearchOPD(search, loading) {
        loading(true);
        await this.searchOPD(search, this);
        loading(false);
      },
      searchOPD: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch(
          "kualifikasi_jabatan_struktural/autocomplete_opd",
          search
        );
        if (search) vm.optionsOPD = options;
      }, 300),
      async onSearchUnitKerja(search, loading) {
        loading(true);
        await this.searchUnitKerja(search, this);
        loading(false);
      },
      searchUnitKerja: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch("kualifikasi_jabatan_struktural/autocomplete_opd_unit_kerja", {
          search: search,
          id_opd: vm.form.id_opd
        });
        if (search) vm.optionsUnitKerja = options;
      }, 300),
    },
  };
  </script>
  